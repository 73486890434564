import React, { useState } from "react";
import { environment } from "../../environment/environment";
import "./price.scss";
export default function Price(props: { price: number, currencyCode: string, showFree?: boolean, classes?: string, hideDiscount?: boolean } = { price: 0, currencyCode: 'GBP', showFree: false, classes: '', hideDiscount: false }) {

  const [discount] = useState(environment.discount);

  const GetPrice = () => {
    if (props.hideDiscount) return GetFullPrice();
    return props.price - (props.price * discount);
  }

  const GetFullPrice = () => {
    return props.price;
  }

  return (<>{
    props.price === 0 && props.showFree === true ? <span className={props.classes}>Free</span> :
      <><span className={props.classes}>£{(GetPrice() / 100).toFixed(2)}</span>
        {discount > 0 && !props.hideDiscount && <small className={`${props.classes} ms-2 fw-light text-decoration-line-through text-muted`}>£{(GetFullPrice() / 100).toFixed(2)}</small>}</>
  }</>);
}


