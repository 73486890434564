import { HTTP_CONSTANTS } from "../../constants/http-constants";
import ProductEntity, { ProductMedia, ProductOptions, VariantEntity, VariantOption } from "../../models/data/product";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";

export class ProductCreateRequest {
    public title: string;
    public description: string;
    public HTMLdescription: string;
    public externalRef: string;
    public available: boolean;
    public price: number;
    public postageProvider: string;
    public options: ProductOptions[];
}
export class VariantCreateRequest {
    public productId: string;
    public title: string;
    public sku: string;
    public available: boolean;
    public price: number;
    public options: VariantOption[];
    public stockCount: number;
}

function GetProduct(
    handle: string,
    callback: ResponseCallback<ProductEntity>,
    admin: boolean = false
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${handle}?${admin ? 'admin=true' : null}`,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetProducts(
    callback: ResponseCallback<ProductEntity[]>,
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product`,
        (resp: ProductEntity[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function SearchProducts(
    query: string,
    callback: ResponseCallback<ProductEntity[]>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/search?q=${query}`,
        (resp: ProductEntity[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function SaveProduct(id: string, request: ProductCreateRequest, callback: ResponseCallback<ProductEntity>): void {
    HttpService.put<ProductCreateRequest, ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${id}`,
        request,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function CreateProduct(request: ProductCreateRequest, callback: ResponseCallback<ProductEntity>): void {
    HttpService.post<ProductCreateRequest, ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product`,
        request,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function DeleteProduct(productId: string, callback: ResponseCallback<any>): void {
    HttpService.delete<ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${productId}`,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}


function CreateVariant(request: VariantCreateRequest, callback: ResponseCallback<ProductEntity>): void {
    HttpService.post<VariantCreateRequest, ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/variant/add`,
        request,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function UpdateVariant(id: string, request: VariantCreateRequest, callback: ResponseCallback<VariantEntity>): void {
    HttpService.put<VariantCreateRequest, VariantEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/variant/${id}`,
        request,
        (resp: VariantEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function DeleteVariant(variantId: string, callback: ResponseCallback<any>): void {
    HttpService.delete<ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/variant/${variantId}`,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function UpdateMedia(productMedia: ProductMedia[], productId: string, callback: ResponseCallback<ProductEntity>): void {
    HttpService.put<ProductMedia[], ProductEntity>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${productId}/media/update`,
        productMedia,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    )
}

function UploadImage(file: File, productId: string, callback: ResponseCallback<ProductEntity>): void {
    const data = new FormData();
    data.append('image', file);
    HttpService.postForm<any, ProductEntity>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${productId}/media/add/${file.name}`,
        data,
        (resp: ProductEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    )
}

function DeleteImage(productId: string, fileName: string, callback: ResponseCallback<ProductEntity>): void {
    fileName = btoa(encodeURI(fileName));
    HttpService.delete<ProductEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${productId}/media/delete/${fileName}`,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function UploadVariantImage(file: File, variantId: string, callback: ResponseCallback<VariantEntity>): void {
    const data = new FormData();
    data.append('image', file);
    HttpService.postForm<any, VariantEntity>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/variant/${variantId}/media/${file.name}`,
        data,
        (resp: VariantEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    )
}

function DeleteVariantImage(variantId: string, callback: ResponseCallback<VariantEntity>): void {
    HttpService.delete<VariantEntity>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/variant/${variantId}/media`,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

const ProductService = {
    SearchProducts,
    GetProduct,
    GetProducts,
    CreateProduct,
    CreateVariant,
    SaveProduct,
    UploadImage,
    UpdateMedia,
    UpdateVariant,
    UploadVariantImage,
    DeleteImage,
    DeleteVariantImage,
    DeleteVariant,
    DeleteProduct
};

export default ProductService;
