import Cookies from "js-cookie";
import { HTTP_CONSTANTS } from "../../constants/http-constants";
import UserSession from "../../models/data/user-session";
import LoginRequest from "../../models/requests/LoginRequest";
import RegisterRequest from "../../models/requests/RegisterRequest";
import AccountDetailResponse from "../../models/responses/AccountDetailResponse";
import { IErrorResp } from "../../models/responses/IErrorResp";
import LoginResponse from "../../models/responses/LoginResponse";
import RegisterResponse from "../../models/responses/RegisterResponse";
import EventEmitter from "../event/event-emitter";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";
import UserSessionService from "./user-session-service";

let currentSession: UserSession | null;
const _sessionChangeEvent = new EventEmitter<void>();
const _sessionExpiredEvent = new EventEmitter<void>();

function _login(loginRequest: LoginRequest, callback: (status: boolean, error?: IErrorResp) => void): void {
	HttpService.post<LoginRequest, LoginResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/login`,
		loginRequest.encode(),
		(_: LoginResponse) => {
			var loggedInSession = new UserSession().FromLoginResponse(_);
			SetCurrentSession(loggedInSession, () => {
				_sessionChangeEvent.emit();
				callback(true);
			});
		},
		(_: HttpError) => {
			callback(false, _.error);
		}
	);
}

function _register(registerRequest: RegisterRequest, callback: (status: boolean, error?: IErrorResp) => void): void {
	HttpService.post<RegisterRequest, RegisterResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/register`,
		registerRequest.encode(),
		(_: RegisterResponse) => {
			var loggedInSession = new UserSession().FromRegisterResponse(_);
			SetCurrentSession(loggedInSession, () => {
				_sessionChangeEvent.emit();
				callback(true);
			});
		},
		(_: HttpError) => {
			callback(false, _.error);
		}
	);
}

function _deactivate(request: { email: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ email: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account/deactivate`,
		request,
		() => {
			_sessionChangeEvent.emit();
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _changePassword(request: { password: string; passwordRepeat: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ password: string; passwordRepeat: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account/change-password`,
		request,
		() => {
			_sessionChangeEvent.emit();
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _getDetails(callback: ResponseCallback<AccountDetailResponse>): void {
	HttpService.get<AccountDetailResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account`,
		(_: AccountDetailResponse) => {
			_sessionChangeEvent.emit();
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _forgotPassword(forgotRequest: { email: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ email: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/forgot`,
		forgotRequest,
		() => {
			_sessionChangeEvent.emit();
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _recoverPassword(
	recoverRequest: { email: string; password: string; passwordRepeat: string; u: string; c: string },
	callback: ResponseCallback<any>
): void {
	HttpService.post<{ email: string; password: string; passwordRepeat: string; u: string; c: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/recover`,
		recoverRequest,
		() => {
			_sessionChangeEvent.emit();
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _logout(callback: () => void): void {
	// localStorage.removeItem("_activeSession");
	Cookies.remove('_activeSession', { domain: window.location.hostname.replace('www', '') });
	currentSession = null;
	_sessionChangeEvent.emit();
	callback();
}

function GetSession(): UserSession | null {
	var storedSession = Cookies.get("_activeSession");
	if (storedSession === undefined) return null
	if (currentSession == null) currentSession = JSON.parse(storedSession as string);
	return currentSession;
}

function SetCurrentSession(_currentSession: UserSession, callback: () => void): void {
	// localStorage.setItem("_activeSession", JSON.stringify(_currentSession));
	Cookies.set('_activeSession', JSON.stringify(_currentSession), { domain: window.location.hostname.replace('www', '') })
	currentSession = { ..._currentSession } as UserSession;
	UserSessionService.sessionUpdatedEvent.emit(currentSession).then(() => callback());
}

function _isLoggedIn(): boolean {
	if (GetSession() == null) return false;
	if (currentSession == null) return false;
	return true;
}

function _getToken(): string {
	if (GetSession() == null) return "";
	if (currentSession == null) return "";
	return currentSession.token;
}

const UserService = {
	sessionChangeEvent: _sessionChangeEvent,
	sessionExpiredEvent: _sessionExpiredEvent,
	getCurrentSession: GetSession,
	login: _login,
	register: _register,
	logout: _logout,
	getToken: _getToken,
	getDetails: _getDetails,
	changePassword: _changePassword,
	forgotPassword: _forgotPassword,
	recoverPassword: _recoverPassword,
	deactivate: _deactivate,
	isLoggedIn: _isLoggedIn,
};

export default UserService;
