import React, { useEffect, useState } from "react";
import "./collection-component.scss";
import ProductCard from "../../product-card/product-card";
import ProductEntity from "../../../models/data/product";
import CollectionService from "../../../services/ui/collection-service";
import { CollectionResponse } from "../../../models/responses/CollectionResp";
export default function CollectionComponent(props: { collectionHandle: string }) {

  const [products, setProducts] = useState<ProductEntity[]>([])

  useEffect(() => {
    CollectionService.GetProducts(props.collectionHandle, {
      success: (res: CollectionResponse) => {
        setProducts(res.products);
      },
      error: (err: any) => { }
    })
    return () => {

    }
  }, [props.collectionHandle])

  return <div className="container">
    <div className="row">
      {products.length > 0 ? products.map((p: ProductEntity, i: number) => {
        return <div key={i} className="my-4 col-6 col-sm-6 col-md-4 col-lg-3">
          <ProductCard product={p} />
        </div>
      }) : <h4 className="w-100 text-center my-4">No Products.</h4>}
    </div>
  </div>;
}


