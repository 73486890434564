import { Buffer } from "buffer";

export default class RegisterRequest {
	constructor(public fullName: string,
		public email: string,
		public password: string,
		public passwordRepeat: string) { }

	// Prevents mutation
	public encode(): RegisterRequest {
		return new RegisterRequest(
			this.fullName,
			this.email,
			Buffer.from(this.password).toString("base64"),
			Buffer.from(this.passwordRepeat).toString("base64")
		);
	}
}
