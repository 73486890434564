import { StatusResponse } from "../models/responses/IErrorResp";

export class ValidationHelper {
    public static ValidateEmail<T>(email: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        errorResp = this.ValidateString(email, 'email', 'Email', errorResp);
        if (!errorResp.status) return errorResp;
        const atPos = email.indexOf("@");
        const dotPos = email.indexOf(".", atPos);
        const atCount = email.split("").filter((c) => c === "@");
        if (atCount.length > 1) errorResp.AddMessage("Email not valid").AddFix("email", "Email must be valid");
        if (email.length < 2) errorResp.AddMessage("Email not valid").AddFix("email", "Email must be valid");
        if (atPos === -1) errorResp.AddMessage("Email not valid").AddFix("email", "Email must be valid");
        if (dotPos === -1) errorResp.AddMessage("Email not valid").AddFix("email", "Email must be valid");
        return errorResp;
    }

    public static ValidateString<T>(str: string, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        if (str === undefined) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} can not be empty`);
        if (str === null) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} can not be empty`);
        if (str === '') errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} can not be empty`);
        return errorResp;
    }


    public static ValidatePhone<T>(str: string, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        errorResp = this.ValidateString(str, key, ViewKey, errorResp);
        if (!errorResp.status) return errorResp;
        if (!str.startsWith('+')) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} needs country identifier ie: +44`);
        if (!str.startsWith('+44') && !str.startsWith('+353')) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} invalid country identifier`);
        return errorResp;
    }

    public static ValidateExpiryMonth<T>(str: string, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        if (Number.parseInt(str) <= 0) errorResp.AddMessage(`${ViewKey} too low`).AddFix(key, `${ViewKey} is invalid`);
        if (Number.parseInt(str) >= 13) errorResp.AddMessage(`${ViewKey} too big`).AddFix(key, `${ViewKey} is invalid`);
        return errorResp;
    }

    public static ValidateExpiryYear<T>(str: string, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        if (Number.parseInt(str) <= 23) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} is invalid`);
        return errorResp;
    }

    public static ValidateMinLength<T>(str: string, length: number, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        if (str.length < length) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} must be longer than ${length} characters`);
        return errorResp;
    }

    public static ValidateMaxLength<T>(str: string, length: number, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        if (str.length > length) errorResp.AddMessage(`${ViewKey} not valid`).AddFix(key, `${ViewKey} must be shorter than ${length} characters`);
        return errorResp;
    }

    public static Blank<T>(str: string, key: string, ViewKey: string, errorResp: StatusResponse<T>): StatusResponse<T> {
        return errorResp;
    }
}
