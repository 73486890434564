import React, { useCallback, useState } from "react";
import "./quantity-select.scss";
import { FaMinus, FaPlus } from "react-icons/fa6";
export default function QuantitySelect(props: { startingQuantity: number, quantityChange: (quantity: number) => void, maxQuantity: number }) {

  const [quantity, setQuantity] = useState<number>(props.startingQuantity);

  const updateQuantity = useCallback((newQuantity: number) => {
    if (newQuantity <= 0) return;
    if (newQuantity >= props.maxQuantity) return;
    setQuantity(newQuantity);
    props.quantityChange(newQuantity);
  }, [props])

  return <div>
    <div className="bg-light d-flex align-items-stretch justify-content-start border overflow-hidden rounded-3" style={{ 'width': 'fit-content' }}>
      <button className="flex-fill btn bg-white rounded-0" onClick={() => updateQuantity(quantity - 1)}>
        <FaMinus />
      </button>
      <div className="text-center d-flex align-items-center justify-content-center px-3 border-start border-end">
        <span className="fs-md-20">{quantity}</span>
      </div>
      <button className="flex-fill btn bg-white rounded-0" onClick={() => updateQuantity(quantity + 1)}>
        <FaPlus />
      </button>
    </div>
  </div>;
}


