import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CollectionService from "../../services/ui/collection-service";
import StatefullInput from "../statefull-input/statefull-input";
import { FaChevronLeft, FaPlus, FaX } from "react-icons/fa6";
import { diff } from 'json-diff-ts';
import CollectionEntity, { CollectionBuilder } from "../../models/data/collection";
import Price from "../price/price";
import ProductEntity from "../../models/data/product";
import AdminService from "../../services/ui/admin-service";

export default function AdminCollectionPage({ pageType }: { pageType: string }) {

    const [searchParams] = useSearchParams();
    const { handle } = useParams();
    const navigation = useNavigate();
    const [isNew] = useState<boolean>(searchParams.get('isNew') === 'true')
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    // collection
    const [collection, setCollection] = useState<CollectionEntity>();
    const [originalCollection, setOriginalCollection] = useState<CollectionEntity>();
    const [products, setProducts] = useState<ProductEntity[]>([]);

    const isInCollection = useCallback((product: ProductEntity) => {
        if (collection === undefined) return false;
        return collection.products.findIndex(cp => cp.id === product.id) > -1
    }, [collection])

    useEffect(() => {
        AdminService.GetProducts({
            success: (_: ProductEntity[]) => {
                setProducts(_);
            },
            error: (err: any) => {
                alert('failed to load products');
            }
        })
    }, [])

    const fetchCollection = (handle: string) => {
        CollectionService.GetCollection(handle, {
            success: (_: CollectionEntity) => {
                setOriginalCollection({ ..._ });
                setCollection({ ..._ });
            },
            error: (err: any) => {
                alert('failed to fetch collection');
                console.error("failed to load collection", err);
            }
        })
    }

    useEffect(() => {
        setOriginalCollection(undefined);
        setCollection(undefined);
    }, [])

    useEffect(() => {
        if (pageType === 'new') {
            setOriginalCollection(new CollectionBuilder().New());
            setCollection(new CollectionBuilder().New());
        } else {
            if (handle === undefined) return;
            fetchCollection(handle);
        }
    }, [handle, searchParams, pageType]);

    useEffect(() => {
        if (originalCollection === undefined) return;
        if (collection === undefined) return;
        const diffs = diff(originalCollection, collection);
        setHasChanged(diffs.length > 0);
    }, [originalCollection, collection])

    const DeleteCollectionHandler = useCallback(() => {
        if (originalCollection === undefined) return;
        // eslint-disable-next-line no-restricted-globals
        const res = confirm('ARE YOU SURE?');
        if (res) {
            CollectionService.DeleteCollection(originalCollection.id, {
                success: function (_: any): void {
                    navigation('/admin?tab=collections');
                },
                error: function (err: any): void {
                    alert('failed to delete collection');
                    console.error(err);
                }
            })
        }
    }, [navigation, originalCollection]);

    const AddToCollectionHandler = useCallback((product: ProductEntity) => {
        if (originalCollection === undefined) return;
        CollectionService.AddProduct(originalCollection.id, product.id, {
            success: function (_: CollectionEntity): void {
                setCollection({ ..._ })
                setOriginalCollection({ ..._ })
            },
            error: function (err: any): void {
                alert('failed to add to collection');
                console.error(err);
            }
        })
    }, [originalCollection]);

    const RemoveFromCollectionHandler = useCallback((product: ProductEntity) => {
        if (originalCollection === undefined) return;
        CollectionService.RemoveProduct(originalCollection.id, product.id, {
            success: function (_: CollectionEntity): void {
                setCollection({ ..._ })
                setOriginalCollection({ ..._ })
            },
            error: function (err: any): void {
                alert('failed to add to collection');
                console.error(err);
            }
        })
    }, [originalCollection]);

    const SaveCollectionHandler = useCallback(() => {
        if (originalCollection === undefined || collection === undefined) return;
        if (!hasChanged) return;
        const request = {
            title: collection.title,
        };
        if (pageType === 'new') {
            // create
            CollectionService.CreateCollection(request, {
                success: (_: CollectionEntity) => {
                    // route to new collection
                    navigation(`/admin/collections/edit/${_.handle}?isNew=true`);
                },
                error: (err: any) => {
                    console.error("Failed to create collection", err);
                }
            })
        } else {
            // update
            CollectionService.SaveCollection(originalCollection.id, request, {
                success: (_: CollectionEntity) => {
                    setOriginalCollection({ ..._ });
                    setCollection({ ..._ });
                },
                error: (err: any) => {
                    console.error("Failed to save collection", err);
                }
            })
        }
    }, [originalCollection, collection, hasChanged, pageType, navigation])

    return <>
        {collection !== undefined &&
            <>
                <div className="mt-4 container mx-auto px-0">
                    {isNew &&
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between py-2 mb-2 bg-success">
                                <h3>Collection created successfully!</h3>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <button className="bg-transparent border-0" onClick={() => navigation('/admin?tab=collections')}>
                                    <FaChevronLeft />
                                </button>

                                <h3 className="my-0">{pageType === 'new' ? 'New Collection' : collection.title}</h3>
                            </div>

                            <div>
                                {pageType !== 'new' && <button onClick={() => window.open(`/collections/${collection.handle}`, '_blank')} className="btn btn-outline-secondary me-2">View</button>}
                                <button onClick={() => SaveCollectionHandler()} disabled={!hasChanged} className={`btn ${hasChanged ? 'btn-primary' : 'btn-outline-secondary'}`}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8 bg-white px-4 py-3 rounded-lg">
                            <StatefullInput stateId={"title"} label={"Title"} defaultValue={collection.title} inputType={"text"} autocompleteType={"none"}
                                onChangeCallback={(_: string) => {
                                    setCollection({ ...collection, title: _ });
                                }} />


                            <h4 className="mt-5">Products In Collection</h4>
                            <div className="my-0 border rounded-3 overflow-hidden border-bottom-0">
                                <table className="table mb-0">
                                    <thead className="bg-secondary text-white">
                                        <tr>
                                            <th>Product</th>
                                            <th>Status</th>
                                            <th>Price</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {collection.products.map((product: ProductEntity, key: number) => {
                                            return <tr key={key} className="">
                                                <td className="py-2 align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                                        <div style={{ maxWidth: 70, maxHeight: 70 }} className="border bg-white rounded-3 overflow-hidden">
                                                            <img src={product.featuredMedia} alt={product.title} className="d-block w-100" />
                                                        </div>
                                                        <p className="my-0">{product.title}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                                        <p className="my-0 w-100">{product.available ? 'Active' : 'Draft'}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                                        <Price price={product.price} currencyCode="GBP" />
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="h-100 d-flex align-items-center justify-content-center">
                                                        <button className="btn btn-outline-danger" onClick={() => RemoveFromCollectionHandler(product)}><FaX /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <h4 className="mt-5">Available Products</h4>
                            <div className="my-0 border rounded-3 overflow-hidden border-bottom-0">
                                <table className="table mb-0">
                                    <thead className="bg-secondary text-white">
                                        <tr>
                                            <th>Product</th>
                                            <th>Status</th>
                                            <th>Price</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product: ProductEntity, key: number) => {
                                            if (isInCollection(product)) return null;
                                            return <tr key={key} className="">
                                                <td className="py-2 align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                                        <div style={{ maxWidth: 70, maxHeight: 70 }} className="border bg-white rounded-3 overflow-hidden">
                                                            <img src={product.featuredMedia} alt={product.title} className="d-block w-100" />
                                                        </div>
                                                        <p className="my-0">{product.title}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                                        <p className="my-0 w-100">{product.available ? 'Active' : 'Draft'}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                                        <Price price={product.price} currencyCode="GBP" />
                                                    </div>
                                                </td>
                                                <td className="py-2 align-middle">
                                                    <div className="h-100 d-flex align-items-center justify-content-center">
                                                        <button className="btn btn-outline-primary" onClick={() => AddToCollectionHandler(product)}><FaPlus /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 bg-white px-4 py-3 rounded-lg">
                            <p>{collection.products.length} Product(s)</p>
                        </div>
                    </div>

                    {pageType !== 'new' && <>
                        <div className="mt-5 mb-5 container mx-auto px-0">
                            <div className="d-flex align-items-center justify-content-end">
                                <button onClick={() => DeleteCollectionHandler()} disabled={originalCollection === undefined || originalCollection.id === undefined} className={`btn btn-danger`}>Delete Collection</button>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </>
        }
    </>;
}