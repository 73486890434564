import { AxiosInstance, AxiosRequestConfig } from "axios";
import UserService from "../session/user-service";

var TOKEN_INTERCEPTOR: number = -1;

function TokenInterceptorAdd(axios: AxiosInstance) {
	TOKEN_INTERCEPTOR = axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			const token = UserService.getToken();
			if (config.headers === undefined) config.headers = {};
			config.headers["Authorization"] = token;
			return config;
		},
		(error: any) => {
			return Promise.reject(error);
		}
	);
}

function TokenInterceptorEject(axios: AxiosInstance) {
	axios.interceptors.request.eject(TOKEN_INTERCEPTOR);
}

const TokenInterceptor = {
	add: TokenInterceptorAdd,
	eject: TokenInterceptorEject,
};

export default TokenInterceptor;
