import React, { useEffect, useState } from "react";
import ProductCard from "../../components/product-card/product-card";
import ProductEntity from "../../models/data/product";
import LoadingComponent from "../../components/loading-component/loading-component";
import ProductService from "../../services/ui/product-service";
export default function ProductsPage() {


  const [products, setProducts] = useState<ProductEntity[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ProductService.GetProducts({
      success: (res: ProductEntity[]) => {
        setProducts([...res]);
        setLoading(false);
      },
      error: (err: any) => {
        setLoading(false);
      }
    })
  }, [])

  const NoResults = () => {
    return <>
      <h4 className="w-100 text-center py-4">No Products.</h4>
    </>
  }

  return (<div className="flex-fill d-flex align-items-stretch justify-content-start">
    {loading ? <LoadingComponent /> : products === undefined || products.length === 0 ? NoResults() : (<div className="container py-5">
      <div className="row">
        <div className="col-12">
          <h3>Our Products</h3>
        </div >
      </div >
      <div className="row">
        <div className="container">
          <div className="row">
            {products.length > 0 ? products.map((p: ProductEntity, i: number) => {
              return <div key={i} className="col-md-3 col-6 py-4">
                <ProductCard product={p} />
              </div>
            }) : <h4 className="w-100 text-center py-4">No Products.</h4>}
          </div>
        </div>
      </div>
    </div >)
    }
  </div >);
}


