import React, { useEffect, useState } from "react";
import "./collection-page.scss";
import { useParams } from "react-router-dom";
import { CollectionResponse } from "../../models/responses/CollectionResp";
import CollectionService from "../../services/ui/collection-service";
import ProductCard from "../../components/product-card/product-card";
import ProductEntity from "../../models/data/product";
import LoadingComponent from "../../components/loading-component/loading-component";
export default function CollectionPage() {

  const { handle } = useParams();
  const [collection, setCollection] = useState<CollectionResponse>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (handle === undefined) return;
    CollectionService.GetProducts(handle, {
      success: (res: CollectionResponse) => {
        setCollection({ ...res });
        setLoading(false);
      },
      error: (err: any) => {
        setLoading(false);
      }
    })
  }, [handle])

  const NoResults = () => {
    return <>
      <h4 className="w-100 text-center py-4">No Products.</h4>
    </>
  }

  return (<div className="flex-fill d-flex align-items-stretch justify-content-start">
    {loading ? <LoadingComponent /> : handle === undefined || collection === undefined ? NoResults() : (<div className="container py-5">
      <div className="row">
        <div className="col-12">
          <h3>{collection.title}</h3>
        </div >
      </div >
      <div className="row">
        <div className="container">
          <div className="row">
            {collection.products.length > 0 ? collection.products.map((p: ProductEntity, i: number) => {
              return <div key={i} className="col-md-3 col-6 py-4">
                <ProductCard product={p} />
              </div>
            }) : <h4 className="w-100 text-center py-4">No Products.</h4>}
          </div>
        </div>
      </div>
    </div >)
    }
  </div >);
}


