import React, { useEffect } from "react";
import "./checkout-complete.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckoutService from "../../services/ui/checkout-service";
import LoadingComponent from "../../components/loading-component/loading-component";
import CartService from "../../services/ui/cart-service";
export default function CheckoutComplete() {

  const [searchParams] = useSearchParams();
  const navigation = useNavigate();

  useEffect(() => {
    const resp = searchParams.get('resp');
    const status = searchParams.get('status');
    if (status !== undefined && status !== null && status === 'declined') { navigation(`/checkout/declined`); return; }
    if (resp === undefined || resp === null || resp === 'declined') { navigation(`/checkout/declined`); return; }
    CheckoutService.AuthTransaction(resp, {
      success(_) {
        if (_.status === 'accepted') CartService.ClearCart();
        navigation(_.status === 'accepted' ? `/checkout/thank-you/${_.id}` : `/checkout/declined`)
      },
      error(err) {
        navigation(`/checkout/declined`)
        console.error(err);
      },
    })
  }, [navigation, searchParams])

  return <div>
    <LoadingComponent />
  </div>;
}


