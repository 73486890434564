import moment from "moment";

export default function CustomFormat(date: Date, format: string = "DD/MM/YY HH:mm"): string {
    return moment(date).format(format);
}

export function getRoundedDate(minutes: number, d: Date = new Date()): Date {
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
    return roundedDate;
}
