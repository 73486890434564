import React, { useEffect, useState } from "react";
import "./checkout-page.scss";
import ThreeDsForm from "../../components/three-ds-form/three-ds-form";
import CheckoutForm from "../../components/checkout-form/checkout-form";
import CheckoutResponse from "../../models/responses/CheckoutResponse";
import { VariantOption } from "../../models/data/product";
import CartService, { CartItem } from "../../services/ui/cart-service";
import { FaTriangleExclamation } from "react-icons/fa6";
export default function CheckoutPage({ status }: { status: string }) {

  const [pageStatus, setPageStatus] = useState<string>(status);
  const [cart] = useState<CartItem[]>(CartService.Cart());
  const [checkoutResponse, setCheckoutResponse] = useState<CheckoutResponse | undefined>(undefined);

  useEffect(() => {
    setCheckoutResponse(undefined);
  }, []);

  return <div className="py-5">
    <div className="container">
      {pageStatus === 'declined' && <div className="row mb-4">
        <div className="col-12">
          <div className="bg-danger p-3 rounded-3 text-white d-flex justify-content-start gap-3 align-items-center">
            <FaTriangleExclamation size={45} color="white" />
            <div>
              <h4 className="mb-1 lh-1">Unable to complete order</h4>
              <p className="my-0 lh-1">Payment failed, please try again.</p>
            </div>
          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-12 col-lg-8 order-1 order-lg-0">
          {checkoutResponse === undefined && <CheckoutForm checkoutResponse={(res: CheckoutResponse) => {
            setPageStatus('in-progress');
            setCheckoutResponse(res)
          }} />}
          {checkoutResponse !== undefined && <ThreeDsForm checkoutResponse={checkoutResponse} />}
        </div>

        <div className="col-12 col-lg-4 order-0 order-lg-1">
          <h2 className="mt-0 w-100 text-center">Cart Summary</h2>
          <div>
            {cart.map((c: CartItem, i: number) => {

              return <div key={i} className="border mb-3 rounded-3 bg-white">
                <div className="p-3 d-flex align-items-stretch justify-content-start flex-column">
                  {/* IMAGE */}
                  <div className="pb-1">
                    <img alt={c.variant.handle} src={c.variant.featuredMedia} className="d-block w-100 rounded-lg overflow-hidden border" />
                  </div>
                  {/* DETAIL */}
                  <div className="d-flex flex-column justify-content-end align-items-bottom">
                    <p className="fs-md-16 fs-mb-13 fw-bolder mb-1">{c.variant.title}</p>
                    <div className="d-sm-flex d-block align-items-center justify-content-start gap-3">
                      {c.variant.options.map((o: VariantOption, i: number) => {
                        return <div key={i} className="border rounded-3 px-1 py-1 bg-dark border-dark w-100 mb-1">
                          <span className="d-block w-100 text-center text-white fs-md-12">{o.viewName}</span>
                        </div>
                      })}
                    </div>
                  </div>
                </div>
              </div>;
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
}


