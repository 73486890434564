import React from "react";
import "./loading-component.scss";
import { Spinner } from "react-bootstrap";
export default function LoadingComponent() {
  return <div className="w-100 d-flex align-items-center justify-content-center flex-column gap-2">
    <div className="pb-5">
      <div className="d-block">
        <Spinner className="fw-bold" style={{ width: 70, height: 70 }} color="" size={'sm'} />
      </div>
      <p className="fw-normal fs-md-20">Loading...</p>
    </div>
  </div>;
}


