import { ValidationHelper } from "../../helpers/ValidationHelper";
import { CartItem } from "../../services/ui/cart-service";
import CheckoutService from "../../services/ui/checkout-service";
import { StatusResponse } from "../responses/IErrorResp";

export default class CheckoutRequest {
    public customerName: string;
    public addressLine1: string;
    public addressLine2: string;
    public town: string;
    public county: string;
    public postcode: string;

    public phoneNumber: string;
    public email: string;

    public cardNumber: string;
    public expiry: string;
    public expiryMonth: string;
    public expiryYear: string;
    public cvv: string;

    public billingIsShipping: boolean = true;
    public b_addressLine1: string;
    public b_addressLine2: string;
    public b_town: string;
    public b_county: string;
    public b_postcode: string;

    public amount: number;
    public items: CartItem[];
    public checksum: string;
}

export class CheckoutValidator {

    public static Build(checkoutRequest: CheckoutRequest): CheckoutRequest {
        checkoutRequest.amount = CheckoutService.GetOrderTotal(checkoutRequest.postcode, checkoutRequest.items).total;
        return checkoutRequest;
    }

    public static Sign(checkoutRequest: CheckoutRequest): CheckoutRequest {
        const temp: any = {
            customerName: checkoutRequest.customerName,
            postcode: checkoutRequest.postcode,
            items: checkoutRequest.items.map(i => i.quantity + i.variant.id + i.variant.price),
        };
        checkoutRequest.checksum = btoa(JSON.stringify(temp));
        return checkoutRequest;
    }

    public static ValidateAndBuild(checkoutRequest: CheckoutRequest): StatusResponse<CheckoutRequest> {
        var resp: StatusResponse<CheckoutRequest> = new StatusResponse<CheckoutRequest>(false, checkoutRequest);

        if (checkoutRequest.billingIsShipping) {
            checkoutRequest.b_addressLine1 = checkoutRequest.addressLine1;
            checkoutRequest.b_addressLine2 = checkoutRequest.addressLine2;
            checkoutRequest.b_town = checkoutRequest.town;
            checkoutRequest.b_county = checkoutRequest.county;
            checkoutRequest.b_postcode = checkoutRequest.postcode;
        }

        resp = ValidationHelper.ValidateString(checkoutRequest.customerName, 'customerName', 'Name', resp);
        resp = ValidationHelper.ValidatePhone(checkoutRequest.phoneNumber, 'phoneNumber', 'Phone Number', resp);
        resp = ValidationHelper.ValidateEmail(checkoutRequest.email, resp);

        resp = ValidationHelper.ValidateString(checkoutRequest.addressLine1, 'addressLine1', 'Address', resp);
        resp = ValidationHelper.ValidateString(checkoutRequest.postcode, 'postcode', 'Postcode', resp);

        resp = ValidationHelper.ValidateString(checkoutRequest.cardNumber, 'cardNumber', 'Card Number', resp);
        resp = ValidationHelper.ValidateString(checkoutRequest.expiry, 'expiry', 'Expiry', resp);
        resp = ValidationHelper.ValidateString(checkoutRequest.cvv, 'cvv', 'CVV', resp);

        resp = ValidationHelper.ValidateString(checkoutRequest.b_addressLine1, 'b_addressLine1', 'Address', resp);
        resp = ValidationHelper.ValidateString(checkoutRequest.b_postcode, 'b_postcode', 'Postcode', resp);

        if (checkoutRequest.expiry !== undefined) {
            [checkoutRequest.expiryMonth, checkoutRequest.expiryYear] = checkoutRequest.expiry.split(' / ');
            resp = ValidationHelper.ValidateExpiryMonth(checkoutRequest.expiryMonth, 'expiry', 'Expiry Month', resp);
            resp = ValidationHelper.ValidateExpiryYear(checkoutRequest.expiryYear, 'expiry', 'Expiry Year', resp);
        }

        resp = ValidationHelper.ValidateMaxLength(checkoutRequest.cvv, 4, 'cvv', 'CVV', resp);
        resp = ValidationHelper.ValidateMinLength(checkoutRequest.cardNumber, 16, 'cardNumber', 'Card Number', resp);
        resp = ValidationHelper.ValidateMaxLength(checkoutRequest.cardNumber, 19, 'cardNumber', 'Card Number', resp);

        return resp.Validate();
    }

    public static ExpiryBuilder(inp: { target: { value: string }, code: string, key: string, altKey: boolean, shiftKey: boolean, ctrlKey: boolean, preventDefault: () => void }, expiryDate: string): string | undefined {
        if (inp.code === 'Tab') {
            return expiryDate;
        }

        if (expiryDate === undefined) expiryDate = '';
        if (inp.code === undefined) return inp.target.value || undefined; // autofill
        var backKey = inp.code === 'Backspace'; // check if backspace
        var dateSlash = inp.code === 'Slash'; // check if forward slash
        var spaceSlash = inp.code === 'Space'; // check if space

        if (inp.altKey || inp.shiftKey || inp.ctrlKey) {
            return expiryDate;
        }

        var index = expiryDate.length; // calculate character index
        if (dateSlash) {
            if (index === 7) {
                inp.preventDefault();
                return expiryDate;
            }

            inp.preventDefault();
            expiryDate = `${expiryDate} / `;
            return expiryDate;
        }
        if (spaceSlash) { inp.preventDefault(); return; }   // ignore date slash and space
        if (backKey) {  // custom backspace logic
            if (expiryDate.endsWith(' / ')) {
                inp.preventDefault();
                var newVal = expiryDate.replace('/', '').replace(' ', '').replace(' ', '');
                expiryDate = newVal;
            } else {
                expiryDate = expiryDate.slice(0, index - 1);
            }
        } else {
            if (index === 2) {
                // after adding month auto add slash for year
                expiryDate = `${expiryDate} / `;
            } else if (index >= 3 && expiryDate.indexOf('/') === -1) {
                // after removing the slash and typing straigh away
                expiryDate = (`${expiryDate.slice(0, 2)} / ${expiryDate.slice(2)}`);
            }

            if (index === 7) {
                inp.preventDefault();
                return expiryDate;
            }

            if (inp.code.startsWith('Digit') && inp.key.length === 1)
                expiryDate = `${expiryDate}${inp.key}`;
            else inp.preventDefault();
        }

        inp.preventDefault();
        return expiryDate;
    }
}