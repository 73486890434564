import { useCallback, useState } from "react";
import StatefullInput from "../../components/statefull-input/statefull-input";
import LoginRequest from "../../models/requests/LoginRequest";
import EventEmitter from "../../services/event/event-emitter";
import { IErrorResp } from "../../models/responses/IErrorResp";
import UserService from "../../services/session/user-service";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {

    const navigate = useNavigate();
    const [loginRequest, setLoginRequest] = useState<LoginRequest>(new LoginRequest('', ''));
    const [stateChangeEvent] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
    const loginPressHandle = useCallback(() => {
        const req = new LoginRequest(loginRequest.email, loginRequest.password);
        UserService.login(req, (status: boolean, error?: IErrorResp) => {
            if (!status && error) stateChangeEvent.emit(error);
            if (status) navigate('/account');
        });

    }, [loginRequest, stateChangeEvent, navigate])

    return <div className="container mx-auto px-0">
        <div className="row">
            <div className="col-12 col-md-2"></div>
            <div className="col-12 col-md-8">
                <h3 className="mt-5 text-decoration-underline">Login</h3>
                <StatefullInput classes="mt-3" stateId={"email"} label={"Email"} defaultValue={""} inputType={"email"} autocompleteType={"email"} onChangeCallback={(_: string) => {
                    setLoginRequest({ ...loginRequest, email: _ } as LoginRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullInput classes="mt-3" stateId={"password"} label={"Password"} defaultValue={""} inputType={"password"} autocompleteType={"password"} onChangeCallback={(_: string) => {
                    setLoginRequest({ ...loginRequest, password: _ } as LoginRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullLabel stateId={"global"} stateChangeEvent={stateChangeEvent} />
                <button className="mt-4 btn btn-lg btn-warning" onClick={loginPressHandle}>Log In</button>
            </div>
            <div className="col-12 col-md-2"></div>
        </div>
    </div>;
}