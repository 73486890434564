import { useState, useCallback } from "react";
import StatefullInput from "../../components/statefull-input/statefull-input";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import RegisterRequest from "../../models/requests/RegisterRequest";
import UserService from "../../services/session/user-service";
import EventEmitter from "../../services/event/event-emitter";
import { IErrorResp } from "../../models/responses/IErrorResp";
import { useNavigate } from "react-router-dom";

export default function RegisterPage() {

    const navigate = useNavigate();
    const [registerRequest, setRegisterRequest] = useState<RegisterRequest>(new RegisterRequest('', '', '', ''));
    const [stateChangeEvent] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

    const registerPressHandle = useCallback(() => {
        UserService.register(new RegisterRequest(registerRequest.fullName, registerRequest.email, registerRequest.password, registerRequest.passwordRepeat), (status: boolean, error?: IErrorResp) => {
            if (!status && error) stateChangeEvent.emit(error);
            if (status) navigate('/account');
        });
    }, [registerRequest, stateChangeEvent, navigate])

    return <div className="container mx-auto px-0">
        <div className="row">
            <div className="col-12 col-md-2"></div>
            <div className="col-12 col-md-8">
                <h3 className="mt-5 text-decoration-underline">Register</h3>
                <StatefullInput classes="mt-3" stateId={"fullName"} label={"Full Name"} defaultValue={""} inputType={"text"} autocompleteType={''} onChangeCallback={(_: string) => {
                    setRegisterRequest({ ...registerRequest, fullName: _ } as RegisterRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullInput classes="mt-3" stateId={"email"} label={"Email"} defaultValue={""} inputType={"email"} autocompleteType={"email"} onChangeCallback={(_: string) => {
                    setRegisterRequest({ ...registerRequest, email: _ } as RegisterRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullInput classes="mt-3" stateId={"password"} label={"Password"} defaultValue={""} inputType={"password"} autocompleteType={"password"} onChangeCallback={(_: string) => {
                    setRegisterRequest({ ...registerRequest, password: _ } as RegisterRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullInput classes="mt-3" stateId={"passwordRepeat"} label={"Password Repeat"} defaultValue={""} inputType={"password"} autocompleteType={"password"} onChangeCallback={(_: string) => {
                    setRegisterRequest({ ...registerRequest, passwordRepeat: _ } as RegisterRequest);
                }} stateChangeEvent={stateChangeEvent} />
                <StatefullLabel stateId={"global"} stateChangeEvent={stateChangeEvent} />
                <button className="mt-4 btn btn-lg btn-warning" onClick={registerPressHandle}>Sign Up</button>
            </div>
            <div className="col-12 col-md-2"></div>
        </div>
    </div>;
}