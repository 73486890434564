import React from "react";
import "./landing-page.scss";

import SwiperCore, { Autoplay, Navigation, Pagination, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { FaCertificate, FaHeadset, FaLightbulb, FaLock, FaMedal, FaTruckRampBox } from "react-icons/fa6";
import { Link } from "react-router-dom";
import CollectionComponent from "../../components/collection-component/components/collection-component";

export default function LandingPage() {

  SwiperCore.use([Pagination, Navigation, Autoplay]);

  const banner_slider_settings: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    loopedSlides: 1,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false
    },
    pagination: {
      el: ".banner_slider_pagination",
      clickable: true
    },
    navigation: {
      prevEl: ".banner_slider_prev",
      nextEl: ".banner_slider_next",
    }
  };

  return <div className="">
    {/* HP Caoursel */}
    <div className="position-relative container text-dark px-0 d-flex flex-column justify-content-between pt-2">
      <Swiper className="flex-fill w-100" {...banner_slider_settings}>
        <SwiperSlide className="container-fluid h-100 p-0">
          <div className="w-100 h-100">
            <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/bc0199fa-468c-4279-804f-9b8fc233fdb7_slide_1.jpg" alt="Banner Of Fireplace" className="d-block w-100" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="container-fluid h-100 p-0">
          <div className="w-100 h-100">
            <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/1d6127c4-d869-4aec-ac29-9f6b07e4afc8_slide_2.jpg" alt="Banner Of Fireplace" className="d-block w-100" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="container-fluid h-100 p-0">
          <div className="w-100 h-100">
            <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/b5e9b868-a238-4600-ad3d-501826ebf65e_slide_3.jpg" alt="Banner Of Fireplace" className="d-block w-100" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="container-fluid h-100 p-0">
          <div className="w-100 h-100">
            <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/41f84971-d9cc-48d6-b259-573f56d2ed17_slide_4.jpg" alt="Banner Of Fireplace" className="d-block w-100" />
          </div>
        </SwiperSlide>
      </Swiper>

      <div style={{ zIndex: 1000 }} className="position-absolute start-0 end-0 bottom-0 d-flex mt-2 align-items-center justify-content-center">
        <div className="">
          <div className="banner_slider_pagination"></div>
        </div>
      </div>
    </div>


    {/* Our advangates */}
    <div className="bg-light py-4">

      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Our Advantages</h3>
            <Link to={'/pages/our-advantages'} className="text-decoration-underline text-primary">Learn More</Link>
          </div>
        </div>
        <div className="row">

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaLightbulb className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">Knowledge</p>
              </div>
            </div>
          </Link>

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaMedal className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">High Quality Assurance</p>
              </div>
            </div>
          </Link>

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaLock className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">Secure Payment System</p>
              </div>
            </div>
          </Link>

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaTruckRampBox className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">Fast and Free Delivery</p>
              </div>
            </div>
          </Link>

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaCertificate className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">2 Year Warranty</p>
              </div>
            </div>
          </Link>

          <Link to={'/pages/our-advantages'} className="col-xl-2 col-md-4 col-6 p-2">
            <div className="bg-white d-flex align-items-center justify-content-center flex-column p-3 rounded-4 shadow-sm h-100">
              <FaHeadset className="d-block h1" />
              <div>
                <p className="mb-0 mt-3 text-center">Professional After Sales Service</p>
              </div>
            </div>
          </Link>

        </div>
      </div>
    </div>

    {/* ABOUT US */}
    <div className="bg-warning text-dark">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="h-100 d-flex align-items-center justify-content-center gap-3 flex-column mx-auto text-center py-5" style={{ maxWidth: 400 }}>
              <h3>ABOUT US</h3>
              <p>FLAMME is a UK - Staffordshire based company and we are proud to provide the warmth and comfort every home deserves.  Our UK customer service team is always ready to assist whenever needed.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 px-0 position-relative">
            <div className="d-none d-md-block position-absolute top-0 start-0 end-0 bottom-0" style={{ background: 'linear-gradient(90deg, rgba(239,135,27,1) 0%, rgba(239,135,27,0) 5%, rgba(239,135,27,0) 95%, rgba(239,135,27,1) 100%)' }}></div>
            <div className="d-block d-md-none position-absolute top-0 start-0 end-0 bottom-0" style={{ background: 'linear-gradient(180deg, rgba(239,135,27,1) 0%, rgba(239,135,27,0) 15%, rgba(239,135,27,0) 100%)' }}></div>
            <img src="https://dosirc917rnix.cloudfront.net/site-media/2c3d5311-8c89-4403-aa7b-a001d5ba1360_Office_Pic_Graded.webp" alt="Banner Of The Warehouse" className="d-block w-100" />
          </div>
        </div>
      </div>
    </div>

    {/* NEW ARRIVALS */}
    <div className="">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <h3>New Arrivals</h3>
          </div>
        </div>
        <div className="row">
          <CollectionComponent collectionHandle="new-arrivals" />
        </div>
      </div>
    </div>

    <div className="container">
      <div className="border-top border-1 w-100 mb-5 pb-4 d-block"></div>
    </div>

    {/* COLLECTION CARDS */}
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
          <a href={`/collections/electric-fireplace-suites`} className="d-block h-100">
            <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded-3 overflow-hidden p-4 h-100 interact">
              <h5 className="fw-bold fs-md-22 fs-mb-22 text-center">Electric Fireplace Suites</h5>
              <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/328ef1af-6e55-4070-9a67-0934de31ead7_ElectricSuites-min.jpg" alt="Banner Electric" className="p-2 d-block w-100" style={{ maxWidth: 400, maxHeight: 200, objectFit: 'contain' }} />
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
          <a href={`/collections/wall-mounted-electric-fires`} className="d-block h-100">
            <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded-3 overflow-hidden p-4 h-100 interact">
              <h5 className="fw-bold fs-md-22 fs-mb-22 text-center">Wall Mounted Electric Fires</h5>
              <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/3dbd1c74-6848-46eb-b66f-d09095fb7ce6_ElectricWallMounted-min.jpg" alt="Banner Wall Mounted" className="p-2 d-block w-100" style={{ maxWidth: 400, maxHeight: 200, objectFit: 'contain' }} />
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
          <a href={`/collections/electric-insert-fires`} className="d-block h-100">
            <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded-3 overflow-hidden p-4 h-100 interact">
              <h5 className="fw-bold fs-md-22 fs-mb-22 text-center">Electric Insert Fires</h5>
              <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/91b10c26-61c4-4440-b857-4483a76c20c9_ElectricInsert-min.jpg" alt="Banner Insert Fires" className="p-2 d-block w-100" style={{ maxWidth: 400, maxHeight: 200, objectFit: 'contain' }} />
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
          <a href={`/collections/electric-stoves`} className="d-block h-100">
            <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded-3 overflow-hidden p-4 h-100 interact">
              <h5 className="fw-bold fs-md-22 fs-mb-22 text-center">Electric Stoves</h5>
              <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/d14b9f8e-5bc8-41f3-9a72-beb506752f7f_ElectricStoves-min.jpg" alt="Banner Stoves" className="p-2 d-block w-100" style={{ maxWidth: 400, maxHeight: 200, objectFit: 'contain' }} />
            </div>
          </a>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="border-bottom border-1 w-100 mt-5 mb-5 d-block"></div>
    </div>

    {/* POPULAR PRODUCTS */}
    <div className="container my-5">
      <div className="row">
        <div className="col-12">
          <h3>Popular Products</h3>
        </div>
      </div>
      <div className="row">
        <CollectionComponent collectionHandle="popular-products" />
      </div>
    </div>

    {/* BOTTOM BANNER */}
    <div className="container-fluid position-relative px-0">
      <div className=" position-relative">
        <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/WEBSTORE_PLAIN_NO_TEXT.png" alt="Room Fireplace" className="w-100 d-block" />
      </div>
      <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-white opacity-3" style={{ zIndex: 1 }}></div>
      <div className="position-absolute bottom-0 start-0 end-0 pb-2" style={{ zIndex: 10 }}>
        <img loading={'lazy'} src="https://dosirc917rnix.cloudfront.net/site-media/ENLIGHTEN_YOUR_ROOM_WITH_FLAMME_TEXT.png" alt="Room Fireplace" className="w-100 d-block mx-auto" style={{ maxWidth: 300 }} />
      </div>
      {/* <div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center flex-column text-dark" style={{ zIndex: 2 }}>
        <p>Enlighten Your Room With FLAMME</p>
        <h4>Find A Fireplace For Every Home</h4>
      </div> */}
    </div>
  </div>;
}
