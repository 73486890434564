import React, { useEffect, useState } from "react";
import "./thank-you-page.scss";
import { Link, useParams } from "react-router-dom";
import CheckoutService from "../../services/ui/checkout-service";
import { Order } from "../../models/responses/CheckoutResponse";
import LoadingComponent from "../../components/loading-component/loading-component";
import { CartItem } from "../../services/ui/cart-service";
import { VariantOption } from "../../models/data/product";
import Price from "../../components/price/price";
import CustomFormat from "../../helpers/time-helper";
export default function ThankYouPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const { orderId } = useParams();
  const [order, setOrder] = useState<Order>();
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOrder(undefined);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (orderId === undefined || orderId === null) return;
    CheckoutService.GetTransaction(orderId, {
      success(_) {
        setOrder({ ..._ });
        setError(undefined);
        setLoading(false);
      },
      error(err) {
        console.error(err);
        setError("We couldn't find your order");
        setLoading(false);
      },
    })
  }, [orderId])

  return <div className="mt-5">

    {loading && <LoadingComponent />}
    {!loading && order && error === undefined && <div>
      {/* className="d-flex align-items-center justify-content-center flex-column px-3" */}
      <div className="mt-4 container mx-auto px-0">
        <div className="row">
          <div className="col-12 col-lg-8 order-1 order-lg-0 bg-white rounded-lg shadow-sm p-3">
            <h3>We have your order!</h3>
            <h4>Order: #WEB{order.orderNumber}</h4>

            <div className="mt-3">
              <p className="mb-0 text-decoration-underline">Order placed: {CustomFormat(order.created)}</p>
              <p className="mb-2">Payment Auth Code: <strong>{order.authCode}</strong></p>
              <p className="mb-3">You will receive email confirmation at: {order.checkoutSession.email}</p>
              <p>Shipping to: {order.checkoutSession.shippingAddress.split(',').map((sl, i) => <span key={`shipping-line-${i}`} className="d-block">{sl}</span>)}</p>

              <div className="pt-4">
                <p className="mb-0">For any questions, please contact us here:</p>
                <a className="d-block text-decoration-underline" href="tel:01785719005">01785 719005</a>
                <a className="d-block text-decoration-underline" href="mailto:customerservice@moddelo.co.uk">customerservice@moddelo.co.uk</a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 order-0 order-lg-1">
            <div className="d-flex align-items-center justify-content-center">
              <h2 className="mt-0 w-100">Order Summary</h2>
              <Price price={order.checkoutSession.amount} currencyCode="GBP" classes="fw-bolder" />
            </div>
            <div>
              {(JSON.parse(order.checkoutSession.items) as CartItem[]).map((c: CartItem, i: number) => {

                return <div key={i} className="border mb-3 rounded-3 bg-white">
                  <div className="p-3 d-flex align-items-stretch justify-content-start flex-column">
                    {/* IMAGE */}
                    <div className="pb-1">
                      <img alt={c.variant.handle} src={c.variant.featuredMedia} className="d-block w-100 rounded-lg overflow-hidden border" />
                    </div>
                    {/* DETAIL */}
                    <div className="d-flex flex-column justify-content-end align-items-bottom">
                      <p className="fs-md-16 fs-mb-13 fw-bolder mb-1">{c.variant.title} x {c.quantity}</p>
                      <p className="fs-md-16 fs-mb-13 fw-bolder mb-1">Courier: {c.variant.postageProvider}</p>
                      <div className="d-sm-flex d-block align-items-center justify-content-start gap-3">
                        {c.variant.options.map((o: VariantOption, i: number) => {
                          return <div key={i} className="border rounded-3 px-1 py-1 bg-dark border-dark w-100 mb-1">
                            <span className="d-block w-100 text-center text-white fs-md-12">{o.viewName}</span>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>}
    {!loading && error !== undefined && <div>

      <div className="d-flex align-items-center justify-content-center flex-column">
        <h3>Something went wrong</h3>
        <p>{error}</p>
        <Link to={'/'}><span className="text-primary text-decoration-underline">Home</span></Link>

        <div className="pt-4">
          <p className="mb-0 text-center">If this is a mistake, please contact us here</p>
          <a className="d-block text-decoration-underline text-center" href="tel:01785719005">01785 719005</a>
          <a className="d-block text-decoration-underline text-center" href="mailto:customerservice@moddelo.co.uk">customerservice@moddelo.co.uk</a>
        </div>
      </div>

    </div>}
  </div>;
}


