import React from "react";
import "./product-card.scss";
import ProductEntity from "../../models/data/product";
import Price from "../price/price";
export default function ProductCard(props: { product: ProductEntity }) {
  return <div className="h-100">
    <a href={`/product/${props.product.handle}`} className="d-flex flex-column justify-content-between rounded-lg border bg-white p-3 h-100">
      <div className="p-2 mb-2 flex-fill d-flex align-items-center">
        <img src={props.product.featuredMedia} loading={'lazy'} className="d-block w-100" alt={props.product.title} />
      </div>
      <div>
        <h5>{props.product.title}</h5>
        <h3 className="mt-3 mb-4"><Price price={props.product.price} currencyCode="GBP" /></h3>

        <div>
          <button className="btn-success border-0 rounded-pill w-100 py-2 fs-md-20 fs-mb-18">SHOP NOW</button>
        </div>
      </div>
    </a>
  </div>;
}


