import { HTTP_CONSTANTS } from "../../constants/http-constants";
import CollectionEntity from "../../models/data/collection";
import ProductEntity from "../../models/data/product";
import { Order } from "../../models/responses/CheckoutResponse";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";

function GetProducts(
    callback: ResponseCallback<ProductEntity[]>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product?admin=true`,
        (resp: ProductEntity[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetCollections(
    callback: ResponseCallback<CollectionEntity[]>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collections`,
        (resp: CollectionEntity[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetImages(
    callback: ResponseCallback<any[]>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/images`,
        (resp: any[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetOrders(
    callback: ResponseCallback<Order[]>
): void {
    HttpService.get<Order[]>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/orders`,
        (resp: Order[]) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetOrder(
    orderId: string,
    callback: ResponseCallback<Order>
): void {
    HttpService.get<Order>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/orders/${orderId}`,
        (resp: Order) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function UploadStaticImage(file: File, callback: ResponseCallback<any>): void {
    const data = new FormData();
    data.append('image', file);
    HttpService.postForm<any, any>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/images/${file.name}`,
        data,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    )
}

const AdminService = {
    GetProducts,
    GetOrders,
    GetOrder,
    GetCollections,
    GetImages,
    UploadStaticImage
};

export default AdminService;
