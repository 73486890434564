import { useEffect, useState } from "react";
import StatefullFileSelect, { FileChangeEvent } from "../statefull-file-select/statefull-file-select";
import EventEmitter from "../../services/event/event-emitter";
import AdminService from "../../services/ui/admin-service";
import { FaClipboard } from "react-icons/fa6";

export default function AdminImageUpload() {

    const [imageChange] = useState<EventEmitter<FileChangeEvent>>(new EventEmitter<FileChangeEvent>());
    const [images, setImages] = useState<any[]>([]);

    useEffect(() => {
        AdminService.GetImages({
            success: (_: any[]) => {
                setImages(_);
            },
            error: function (err: any): void {
                console.error("Error fetching images" + err);
            }
        });
    }, [])

    useEffect(() => {
        const pic = imageChange.subscribe((fileChange: FileChangeEvent) => {
            if (fileChange.file.length < 1) return;
            AdminService.UploadStaticImage(fileChange.file[0], {
                success: (_: string) => {
                    setImages([...images, _])
                },
                error: (err: any) => {
                    console.error(err);
                }
            })
        });

        return () => {
            imageChange.unsubscribe(pic);
        }
    }, [imageChange, images]);

    return <>
        <div>
            <StatefullFileSelect inputKey={""} classes="w-100" label={'Upload New Image'} fileSelected={imageChange} />
            <div className="d-flex align-items-stretch justify-content-start gap-4 flex-wrap mt-4">
                {images.map((img, i) => {
                    return <div key={`img-${i}`} style={{ maxWidth: 200 }} className="d-flex flex-column gap-1 border rounded-3 overflow-hidden">
                        <div className="bg-light p-2 flex-fill d-flex align-items-center"><img src={img} alt="From server" className="d-block w-100" /></div>
                        <div className="p-2">
                            <button onClick={() => navigator.clipboard.writeText(img)} className="w-100 btn btn-outline-primary d-flex align-items-center justify-content-center gap-3"><span className="d-block my-0">Copy Link</span><FaClipboard /></button>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}