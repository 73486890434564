import Cookies from "js-cookie";
import { VariantEntity } from "../../models/data/product";
import EventEmitter from "../event/event-emitter";
import HttpService, { HttpError } from "../http/http-service";
import { HTTP_CONSTANTS } from "../../constants/http-constants";

export class CartItem {
    handle: string;
    variant: VariantEntity;
    quantity: number;
}

var __cart: CartItem[] = [];
const CART_CHANGED: EventEmitter<{ latestAdd: string | undefined, items: CartItem[] }> = new EventEmitter<{ latestAdd: string | undefined, items: CartItem[] }>();

function LoadCart(): CartItem[] {
    var cart = Cookies.get('cart');
    if (cart !== undefined) __cart = JSON.parse(cart);
    return __cart;
}

function SaveCart(_c: CartItem[]): void {
    Cookies.set('cart', JSON.stringify(_c), { domain: window.location.hostname.replace('www', '') })
    LoadCart();
}

function Cart(): CartItem[] {
    return LoadCart();
}

function ClearCart(): void {
    SaveCart([]);
    CART_CHANGED.emit({ latestAdd: undefined, items: [] })
}

function AddToCart(_item: CartItem): void {
    var eid = __cart.findIndex(c => c.variant.id === _item.variant.id);
    if (eid > -1) {
        _item.quantity += __cart[eid].quantity;
        __cart.splice(eid, 1, _item)
    } else {
        __cart.push(_item);
    }
    SaveCart(__cart);
    setTimeout(() => {
        CART_CHANGED.emit({ latestAdd: _item.variant.title, items: __cart })

        try {
            HttpService.get<any>(
                `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/product/${_item.variant.id}/atc`,
                (resp: any[]) => { },
                (error: HttpError) => { }
            )
        } catch { }
    }, 250);
}

function RemoveFromCart(_item: CartItem): void {
    var eid = __cart.findIndex(c => c.variant.id === _item.variant.id);
    if (eid > -1) __cart.splice(eid, 1)
    CART_CHANGED.emit({ latestAdd: '', items: __cart })
    SaveCart(__cart);
}

function ReduceQuantity(_item: CartItem): void {
    var eid = __cart.findIndex(c => c.variant.id === _item.variant.id);
    if (eid > -1) __cart[eid].quantity--;

    SaveCart(__cart);
}

function IncreaseQuantity(_item: CartItem): void {
    var eid = __cart.findIndex(c => c.variant.id === _item.variant.id);
    if (eid > -1) __cart[eid].quantity++;

    SaveCart(__cart);
}

function SetQuantity(_item: CartItem, quantity: number): void {
    var eid = __cart.findIndex(c => c.variant.id === _item.variant.id);
    if (eid > -1) __cart[eid].quantity = quantity;

    SaveCart(__cart);
}

const CartService = {
    CART_CHANGED,
    //
    SaveCart,
    LoadCart,
    ClearCart,
    Cart,
    AddToCart,
    RemoveFromCart,
    ReduceQuantity,
    IncreaseQuantity,
    SetQuantity
};

export default CartService;
