import { useNavigate } from "react-router-dom";
import UserService from "../../services/session/user-service";

export default function AccountPage() {

    const navigate = useNavigate();

    return <div>
        <div className="bg-light py-4">

            <div className="container px-0">
                <div className="row px-0">
                    <button onClick={() => UserService.logout(() => {
                        navigate('/')
                    })}>Log Out</button>
                </div>
            </div>
        </div>
    </div>;
}