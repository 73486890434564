import React, { useCallback, useState } from "react";
import "./buy-button.scss";
import QuantitySelect from "../quantity-select/quantity-select";
import CartService from "../../services/ui/cart-service";
import ProductEntity, { VariantEntity } from "../../models/data/product";
import { useNavigate } from "react-router-dom";
export default function BuyButton(props: { product: ProductEntity, variant: VariantEntity }) {

  const [quantity, setQuantity] = useState<number>(1);
  const navigation = useNavigate();

  const QuantityUpdate = useCallback((_q: number) => {
    setQuantity(_q);
  }, [])

  const AddToCart = useCallback((gotoCheckout: boolean) => {
    CartService.AddToCart({
      handle: props.product.handle,
      variant: props.variant,
      quantity: quantity
    })
    if (gotoCheckout) navigation('/checkout');
  }, [navigation, props.product.handle, props.variant, quantity])

  return <div className="pb-4">
    <div>
      <h5>Quantity</h5>
      <QuantitySelect startingQuantity={1} maxQuantity={50} quantityChange={(quantity: number) => QuantityUpdate(quantity)} />
    </div>

    <div className="d-md-flex d-block align-items-center justify-content-start gap-2 pt-5">
      <button className="w-100 btn btn-lg btn-outline-dark mb-2" onClick={() => AddToCart(false)}>ADD TO CART</button>
      <button className="w-100 btn btn-lg btn-warning mb-2" onClick={() => AddToCart(true)}>BUY NOW</button>
    </div>
  </div>;
}


