import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import OrdersTabComponent from "../../components/admin-tabs/orders-tab-component";
import CollectionsTabComponent from "../../components/admin-tabs/collections-tab-component";
import ProductsTabComponent from "../../components/admin-tabs/products-tab-component";
import SettingsTabComponent from "../../components/admin-tabs/settings-tab-component";

export default function AdminPage() {

    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>(searchParams.get('tab') || 'orders')

    const routeComponents = [
        { tab: 'orders', component: <OrdersTabComponent /> },
        { tab: 'collections', component: <CollectionsTabComponent /> },
        { tab: 'products', component: <ProductsTabComponent /> },
        { tab: 'settings', component: <SettingsTabComponent /> }
    ];

    useEffect(() => {
        window.history.replaceState(null, '', `/admin?tab=${activeTab}`)
    }, [activeTab]);

    return <div className="bg-light flex-fill px-0 py-2 d-flex">
        <div className="container-fluid px-0 flex-fill d-flex align-items-stretch">
            <div className="px-2">
                <nav className="nav flex-column nav-pills nav-fill">
                    <button className={`nav-link text-start ${activeTab === 'orders' ? 'active' : ''}`} onClick={() => setActiveTab('orders')}>Orders</button>
                    <button className={`nav-link text-start ${activeTab === 'collections' ? 'active' : null}`} onClick={() => setActiveTab('collections')}>Collections</button>
                    <button className={`nav-link text-start ${activeTab === 'products' ? 'active' : null}`} onClick={() => setActiveTab('products')}>Products</button>
                    <button className={`nav-link text-start ${activeTab === 'settings' ? 'active' : null}`} onClick={() => setActiveTab('settings')}>Settings</button>
                </nav>
            </div>
            <div className="bg-white flex-fill p-3">
                {routeComponents.find(r => r.tab === activeTab)?.component}
            </div>
        </div>
    </div>;
}