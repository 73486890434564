export const environment: {
	version: string;
	production: boolean;
	protocol: string;
	baseAddress: string;
	stripeKey: string;
	discount: number;
} = {
	version: "0.0.1",
	production: true,
	protocol: "https://",
	baseAddress: "www.fireplaceshops.com/api",
	stripeKey: "",
	discount: 0.00
};
