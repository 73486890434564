import LoginResponse from "../responses/LoginResponse";
import RegisterResponse from "../responses/RegisterResponse";

export enum AccountType {
	Standard = 0,
	Admin = 1
}

export default class UserSession {
	public token: string;
	public refreshToken: string;
	public issuedDate: Date;
	public expiryDate: Date;
	public name: string;
	public email: string;
	public accountType: number;

	public FromLoginResponse(_: LoginResponse): UserSession {
		this.token = _.token;
		this.refreshToken = _.refreshToken;
		this.issuedDate = _.issuedDate;
		this.expiryDate = _.expiryDate;
		this.name = _.name;
		this.email = _.email;
		this.accountType = _.accountType;
		return this;
	}

	public FromRegisterResponse(_: RegisterResponse): UserSession {
		this.token = _.token;
		this.refreshToken = _.refreshToken;
		this.issuedDate = _.issuedDate;
		this.expiryDate = _.expiryDate;
		this.name = _.name;
		this.email = _.email;
		this.accountType = _.accountType;
		return this;
	}
}
