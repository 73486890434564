import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AdminService from "../../services/ui/admin-service";
import CollectionEntity from "../../models/data/collection";

export default function CollectionsTabComponent() {

    const [collections, setCollections] = useState<CollectionEntity[]>([]);
    const navigation = useNavigate();

    useEffect(() => {
        AdminService.GetCollections({
            success: (_: CollectionEntity[]) => {
                setCollections(_);
            },
            error: (err: any) => {
                console.error('failed to load collections');
            }
        })
    }, [])

    const OpenProductHandle = useCallback((product: CollectionEntity) => {
        navigation(`/admin/collections/edit/${product.handle}`)
    }, [navigation])

    const AddNewCollectionHandle = useCallback(() => {
        navigation(`/admin/collections/create`)
    }, [navigation])

    return <>
        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
            <h3 className="my-0">Collections</h3>
            <div>
                <button className="btn btn-dark" onClick={() => AddNewCollectionHandle()}>Add Collection</button>
            </div>
        </div>
        <table className="table rounded-3 overflow-hidden">
            <thead className="bg-secondary text-white">
                <tr>
                    <th>Collection</th>
                    <th className="text-center"># of Products</th>
                </tr>
            </thead>
            <tbody>
                {collections.map((collection: CollectionEntity, key: number) => {
                    return <tr key={key} className="interact-hover" onClick={() => OpenProductHandle(collection)}>
                        <td className="py-2 align-middle">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0">{collection.title}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-center">
                                <p className="text-center my-0 w-100">{collection.products.length} Products</p>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </>;
}