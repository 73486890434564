import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
export default function Footer() {
  return <div className="bg-warning px-0 text-dark">
    <div className="container">
      <div className="row pt-4">
        <div className="col-12 col-md-4">
          <h4>Support</h4>
          <Link className="d-block py-2" to={'/pages/privacy-policy'}>Privacy</Link>
          <Link className="d-block py-2" to={'/pages/terms-and-conditions'}>Terms And Conditions</Link>
          <Link className="d-block py-2" to={'/pages/faqs'}>FAQ's</Link>
        </div>
        <div className="col-12 col-md-4">
          <h4>Contact</h4>
          <p className="d-block py-2">FLAMME by Moddelo Ltd<br />Unit 6a-6e<br />Airfield Industrial Estate<br />ST18 0PF</p>
          <a className="d-block text-decoration-underline" href="tel:01785719005">01785 719005</a>
          <a className="d-block text-decoration-underline" href="mailto:customerservice@moddelo.co.uk">customerservice@moddelo.co.uk</a>
        </div>
        <div className="col-12 col-md-4">
          <h4>Support</h4>
          <p>Subscribe to our newsletter for product updates, new arrivals and stock notifications</p>
          <div id="mdp_fa9af473-068f-4551-9c0f-11d487f0af20"></div>
        </div>
      </div>
      <div className="row border-top border-dark mt-4 pt-4 pb-4">
        <div className="d-flex align-items-center justify-content-between">
          <span className="flex-fill d-block">©2024 by Moddelo</span>
          <Link to="https://www.lukestonier.com" className="d-flex align-items-center justify-content-end">
            <span className="ms-1 d-block">Site by Luke Stonier</span>
          </Link>
        </div>
      </div>
    </div>
  </div>;
}


