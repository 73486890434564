/* eslint-disable no-restricted-globals */
import { FaTrash, FaFloppyDisk, FaRotateLeft } from "react-icons/fa6";
import ProductEntity, { VariantEntity, VariantOption } from "../../models/data/product";
import Price from "../price/price";
import StatefullInput from "../statefull-input/statefull-input";
import { useCallback, useEffect, useState } from "react";
import ProductService from "../../services/ui/product-service";
import { diff } from "json-diff-ts";
import EventEmitter from "../../services/event/event-emitter";
import StatefullFileSelect, { FileChangeEvent } from "../statefull-file-select/statefull-file-select";

export function AdminVariantRow({ product, productVariant }: { product: ProductEntity, productVariant: VariantEntity }) {
    const [variant, setVariant] = useState<VariantEntity>({ ...productVariant })
    const [originalVariant, setOriginalVariant] = useState<VariantEntity>({ ...variant })
    const [VariantImageChange] = useState<EventEmitter<FileChangeEvent>>(new EventEmitter<FileChangeEvent>());
    const [newVariantImage, setNewVariantImage] = useState<FileChangeEvent>();

    useEffect(() => {
        const pic = VariantImageChange.subscribe((fileChange: FileChangeEvent) => {
            if (originalVariant === undefined) return;
            console.log(originalVariant);
            setNewVariantImage(fileChange);
            if (fileChange.file.length < 1) return;
            ProductService.UploadVariantImage(fileChange.file[0], originalVariant.id, {
                success: (_: VariantEntity) => {
                    setVariant({ ..._ });
                    setOriginalVariant({ ..._ });
                    setNewVariantImage(undefined);
                },
                error: (err: any) => {
                    console.error(err);
                }
            })
        });

        return () => {
            VariantImageChange.unsubscribe(pic);
        }
    }, [originalVariant, VariantImageChange]);

    const RevertVariantHandler = useCallback(() => {
        if (variant === undefined) return;
        if (variant === undefined) return;
        setVariant({ ...originalVariant })
    }, [originalVariant, variant]);

    const hasChanged = useCallback((newVariant: VariantEntity) => {
        if (originalVariant === undefined) return;
        const diffs = diff(originalVariant, newVariant);
        return diffs.length > 0;
    }, [originalVariant])

    const DeleteVariantHandler = useCallback(() => {
        ProductService.DeleteVariant(variant.id, {
            success: (_: VariantEntity) => {
                // this has removed this
            },
            error: (err: any) => {
                console.error(err);
            }
        })
    }, [variant]);

    const UpdateVariantHandler = useCallback(() => {
        ProductService.UpdateVariant(originalVariant.id, {
            productId: product.id,
            title: variant.title,
            sku: variant.sku,
            available: variant.available === undefined ? false : variant.available,
            price: variant.price,
            options: variant.options,
            stockCount: variant.stockCount,
        }, {
            success: function (_: VariantEntity): void {
                setVariant({ ..._ });
                setOriginalVariant({ ..._ });
                setNewVariantImage(undefined);
            },
            error: function (err: any): void {
                console.log(err);
            }
        })
    }, [originalVariant, product, variant]);

    const DeleteProductImageHandler = useCallback(() => {
        if (originalVariant === undefined) return;
        ProductService.DeleteVariantImage(originalVariant.id, {
            success: function (_: VariantEntity): void {
                setVariant({ ..._ });
                setOriginalVariant({ ..._ });
                setNewVariantImage(undefined);
            },
            error: function (err: any): void {
                console.log(err);
            }
        })
    }, [originalVariant]);

    return <div className="d-flex align-items-start justify-content-between gap-2 mb-4">
        <details className="w-100">
            <summary className="bg-light py-2 px-3 rounded-3 p-2">
                <div className="w-100 d-flex align-items-center justify-content-between gap-2 pe-3">
                    <h5>{variant.title}</h5> <Price price={variant.price} currencyCode={"GBP"} /> <span>{variant.stockCount} in Stock / {variant.available ? 'Active' : 'Disabled'}</span>
                </div>
            </summary>
            <div className="border px-3 py-3 border-1 mt-2 rounded-3 d-flex align-items-start justify-content-between gap-2">
                <div className="flex-shrink-0" style={{ maxWidth: 150 }}>
                    <StatefullFileSelect inputKey={variant.id} classes="w-100" label={'Select Image'} fileSelected={VariantImageChange} />
                    <div className="border border-dark rounded-lg overflow-hidden position-relative">
                        {variant.featuredMedia && <>
                            <img className='w-100 d-block' alt={variant.title} src={variant.featuredMedia} />
                            <div className="position-absolute top-0 end-0 p-1">
                                <button className="btn btn-outline-danger" onClick={() => DeleteProductImageHandler()}><FaTrash size={15} /></button>
                            </div>
                        </>
                        }
                        {(variant.featuredMedia === undefined || variant.featuredMedia === '' || variant.featuredMedia === null) && <div className="d-flex align-items-between justify-content-center py-4"><p className="my-0">No Image</p></div>}
                    </div>
                    {newVariantImage === undefined ? <></> : <button className="btn btn-outline-warning w-100 mt-1" onClick={() => {
                        setNewVariantImage(undefined);
                    }}><FaRotateLeft /> Revert to original</button>}
                </div>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-between gap-2">
                        <StatefullInput classes="flex-fill" stateId={"variantTitle"} label={"Variant Title"} defaultValue={variant.title} inputType={"text"} autocompleteType={"none"}
                            onChangeCallback={(_: string) => {
                                setVariant({ ...variant, title: _ });
                            }} />

                        <StatefullInput classes="" stateId={"variantSKU"} label={"SKU"} defaultValue={variant.sku} inputType={"text"} autocompleteType={"none"}
                            onChangeCallback={(_: string) => {
                                setVariant({ ...variant, sku: _ });
                            }} />

                        <StatefullInput classes="" stateId={"price"} label={"Price eg: 123.45"} defaultValue={(variant.price / 100).toFixed(2)} inputType={"number"} autocompleteType={"none"}
                            onChangeCallback={(_: string) => {
                                const price = Number.parseInt((Number.parseFloat(_) * 100).toFixed(0));
                                setVariant({ ...variant, price: price });
                            }} />
                    </div>

                    <div className="d-flex align-items-center justify-content-start flex-wrap gap-2 mt-2">

                        <StatefullInput stateId={"available"} label={"Availability"} defaultValue={variant.available ? 'active' : 'inactive'} inputType={"select"} autocompleteType={""} options={[{ view: 'Active', value: 'active' }, { view: 'Inactive', value: 'inactive' }]} onChangeCallback={(_: string) => {
                            setVariant({ ...variant, available: _ === 'active' });
                        }} />

                        <StatefullInput classes="" stateId={"stockCount"} label={"Stock Count"} defaultValue={variant.stockCount.toString()} inputType={"number"} autocompleteType={"none"}
                            onChangeCallback={(_: string) => {
                                const toSet = Number.parseInt(_);
                                if (isNaN(toSet)) setVariant({ ...variant, stockCount: 0 });
                                else setVariant({ ...variant, stockCount: toSet });
                            }} />

                        {
                            variant.options !== undefined && product.options.map((po, i) => {
                                return <div key={`${po.optionHandle}-${i}`}>
                                    <StatefullInput stateId={`${po.optionHandle}-${i}`} label={po.optionName} defaultValue={variant.options.find(vo => vo.optionHandle === po.optionHandle)?.handle || ''} inputType={"select"} autocompleteType={""} options={
                                        [{ view: 'Select An Option', value: '' }, ...po.options.map((op, i2) => { return { view: op.viewName, value: op.handle } })]
                                    } onChangeCallback={(_: string) => {
                                        if (_ === '') return;
                                        const tempOptions = JSON.parse(JSON.stringify(variant.options)) as VariantOption[]
                                        const updatedOptionIndex = tempOptions.findIndex(o => o.optionHandle === po.optionHandle);
                                        if (updatedOptionIndex === -1) tempOptions.push({ handle: _, viewName: po.options.find(op => op.handle === _)?.viewName, optionName: po.optionName, optionHandle: po.optionHandle } as VariantOption);
                                        else tempOptions[updatedOptionIndex] = { ...tempOptions[updatedOptionIndex], handle: _, viewName: po.options.find(op => op.handle === _)?.viewName } as VariantOption;
                                        setVariant({ ...variant, options: [...tempOptions] });
                                    }} />
                                </div>
                            })
                        }
                    </div>


                    {hasChanged(variant) && <div>
                        <button className="mt-3 btn btn-primary" onClick={() => UpdateVariantHandler()}>
                            <FaFloppyDisk /> Save Changes
                        </button>
                        <button className="ms-2 mt-3 btn btn-outline-danger" onClick={() => RevertVariantHandler()}>
                            <FaRotateLeft /> Revert Changes
                        </button>
                    </div>}
                </div>
            </div>
        </details>
        <div>
            <button className="btn btn-danger" onClick={() => DeleteVariantHandler()}><FaTrash /></button>
        </div>
    </div>
}

export default function AdminVariantEditor({ product }: { product: ProductEntity }) {

    // variants
    const [variants, setVariants] = useState<VariantEntity[]>([...product.variants]);
    const [newVariant, setNewVariant] = useState<VariantEntity>();

    const CreateVariantHandler = useCallback(() => {
        if (product === undefined || newVariant === undefined) return;
        ProductService.CreateVariant({
            productId: product.id,
            title: newVariant.title,
            sku: newVariant.sku,
            available: newVariant.available === undefined ? false : newVariant.available,
            price: newVariant.price,
            options: newVariant.options,
            stockCount: 0
        }, {
            success: (_: ProductEntity) => {
                setVariants([..._.variants])
                setNewVariant(undefined);
            },
            error: (err: any) => {
                console.error(err);
            }
        })
    }, [newVariant, product])

    return <div className="mt-5 mb-5 container mx-auto px-0">
        <div className="d-flex align-items-center justify-content-between py-2 mb-0">
            <h3>Product Variants</h3>
        </div>

        <div className="bg-white px-4 py-3 rounded-lg">
            {variants !== undefined && <>
                <div>
                    {variants.map((v, i) => {
                        return <div key={`${v.handle}-${i}`}>
                            <AdminVariantRow product={product} productVariant={v} />
                        </div>
                    })}
                </div>
            </>}

            {newVariant !== undefined &&
                <div className="mb-4">
                    <h3 className="pt-4">New Variant</h3>
                    <div className="d-flex align-items-start justify-content-between gap-2">
                        <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <StatefullInput classes="flex-fill" stateId={"variantTitle"} label={"Variant Title"} defaultValue={newVariant.title} inputType={"text"} autocompleteType={"none"}
                                    onChangeCallback={(_: string) => {
                                        setNewVariant({ ...newVariant, title: _ });
                                    }} />
                                <StatefullInput classes="flex-fill" stateId={"variantSKU"} label={"Variant SKU"} defaultValue={newVariant.sku} inputType={"text"} autocompleteType={"none"}
                                    onChangeCallback={(_: string) => {
                                        setNewVariant({ ...newVariant, sku: _ });
                                    }} />
                                <StatefullInput stateId={"available"} label={""} defaultValue={'inactive'} inputType={"select"} autocompleteType={""} options={[{ view: 'Active', value: 'active' }, { view: 'Inactive', value: 'inactive' }]} onChangeCallback={(_: string) => {
                                    setNewVariant({ ...newVariant, available: _ === 'available' });
                                }} />

                                <StatefullInput classes="" stateId={"price"} label={"Price eg: 123.45"} defaultValue={'0'} inputType={"number"} autocompleteType={"none"}
                                    onChangeCallback={(_: string) => {
                                        const price = Number.parseInt((Number.parseFloat(_) * 100).toFixed(0));
                                        setNewVariant({ ...newVariant, price: price });
                                    }} />
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-start flex-wrap gap-2 mt-2">
                                {
                                    product.options.map((po, i) => {
                                        return <div key={`${po.optionHandle}-${i}`}>
                                            <StatefullInput stateId={`${po.optionHandle}-${i}`} label={po.optionName} defaultValue={''} inputType={"select"} autocompleteType={""} options={
                                                [{ view: 'Select An Option', value: '' }, ...po.options.map((op, i2) => { return { view: op.viewName, value: op.handle } })]
                                            } onChangeCallback={(_: string) => {
                                                if (_ === '') {
                                                    return;
                                                }
                                                if (newVariant.options === undefined) {
                                                    const tempVariantOptions = [{ handle: _, viewName: po.options.find(op => op.handle === _)?.viewName, optionName: po.optionName, optionHandle: po.optionHandle } as VariantOption]
                                                    setNewVariant({ ...newVariant, options: [...tempVariantOptions] })
                                                } else {
                                                    const tempVariantOptions = JSON.parse(JSON.stringify(newVariant.options)) as VariantOption[];
                                                    const updatedOptionIndex = tempVariantOptions.findIndex(o => o.optionHandle === po.optionHandle);
                                                    if (updatedOptionIndex === -1) tempVariantOptions.push({ handle: _, viewName: po.options.find(op => op.handle === _)?.viewName, optionName: po.optionName, optionHandle: po.optionHandle } as VariantOption);
                                                    else tempVariantOptions[updatedOptionIndex] = { ...newVariant.options[updatedOptionIndex], handle: _, viewName: po.options.find(op => op.handle === _)?.viewName } as VariantOption;
                                                    setNewVariant({ ...newVariant, options: [...tempVariantOptions] })
                                                }
                                            }} />
                                        </div>
                                    })
                                }


                            </div>
                        </div>

                        <div>
                            <div style={{ height: 24 }}></div>
                            <button className="btn btn-primary" onClick={() => CreateVariantHandler()}><FaFloppyDisk /></button>
                        </div>
                    </div>
                </div>}

            {newVariant === undefined && <button className={`btn btn-primary`} onClick={() => setNewVariant(new VariantEntity())}>Add Variant</button>}
            {newVariant !== undefined && <button className={`btn btn-outline-danger`} onClick={() => { if (confirm("Are you sure you want to cancel?")) setNewVariant(undefined) }}>Cancel</button>}
        </div>
    </div>
}