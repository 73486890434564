import React, { useCallback, useState } from "react";
import "./cart-page.scss";
import CartService, { CartItem } from "../../services/ui/cart-service";
import { VariantOption } from "../../models/data/product";
import QuantitySelect from "../../components/quantity-select/quantity-select";
import Price from "../../components/price/price";
import { FaTrash } from "react-icons/fa6";
import { Link } from "react-router-dom";
export default function CartPage() {

  const [cart, setCart] = useState<CartItem[]>(CartService.Cart());

  const UpdateCartItem = useCallback((item: CartItem, quantity: number) => {
    var id = cart.findIndex(c => c.variant.id === item.variant.id);
    if (id <= -1) return;
    cart[id].quantity = quantity
    CartService.SetQuantity(item, quantity);
    setCart([...cart])
  }, [cart]);

  const RemoveCartItem = useCallback((item: CartItem) => {
    var id = cart.findIndex(c => c.variant.id === item.variant.id);
    if (id <= -1) return;
    cart.splice(id, 1)
    CartService.RemoveFromCart(item)
    setCart([...cart]);
  }, [cart])

  return <div className="py-5">
    <div className="container px-3 px-md-0">
      <h2>My Cart</h2>
    </div>
    <div className="px-3 px-md-0">
      {cart.length === 0 ? <div>
        <h4 className="w-100 text-center pt-4 mb-2">No Products.</h4>
        <Link className="text-center text-primary d-block text-decoration-underline" to={'/'}>Back to home</Link>
      </div> : null}
      {cart.map((c: CartItem, i: number) => {
        return <div key={i} className="pb-3">
          <div className="border rounded-lg container bg-white">
            <div className="row">
              <div className="col-md-8 col-12 p-0">
                <div className="p-3 d-flex align-items-stretch justify-content-start">
                  {/* IMAGE */}
                  <div>
                    <img alt={c.variant.handle} src={c.variant.featuredMedia} className="d-block w-100 rounded-lg overflow-hidden border" style={{ 'maxWidth': '150px' }} />
                  </div>
                  {/* DETAIL */}
                  <div className="ps-3 d-flex flex-column justify-content-end align-items-bottom">
                    <Link to={`/product/${c.handle}`} className="fs-md-23 fs-mb-16 fw-bolder mb-1 text-dark text-decoration-none">{c.variant.title}</Link>
                    <div className="d-lg-flex d-block align-items-center justify-content-start gap-3">
                      {c.variant.options.map((o: VariantOption, i: number) => {
                        return <div key={i} className="">
                          <span className="text-muted">{o.optionName}: </span><strong>{o.viewName}</strong>
                        </div>
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {/* ACTIONS */}
              <div className="col-md-4 col-12 p-0">
                <div className="d-flex flex-md-column-reverse align-items-end h-100 justify-content-between justify-content-md-start gap-2 pt-0 pt-md-3 p-3 flex-wrap">
                  <div className="d-flex align-items-center justify-content-between flex-row-reverse flex-md-row gap-2">
                    <button className="btn p-2 d-flex align-items-center justify-content-center" onClick={() => RemoveCartItem(c)}>
                      <FaTrash className="lh-sm" />
                    </button>
                    <QuantitySelect startingQuantity={c.quantity} quantityChange={(quantity: number) => UpdateCartItem(c, quantity)} maxQuantity={50} />
                  </div>
                  <h2 className="my-0 fs-md-23 fs-mb-30 lh-sm"><Price price={c.variant.price * c.quantity} currencyCode="GBP" /></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      })}
    </div>
    {cart !== undefined && cart.length > 0 &&
      <div className="container px-3 px-md-0">
        <Link to={'/checkout'}>
          <div className="ms-auto me-0 d-block mt-2 btn btn-warning border-0 btn-lg w-100" style={{ maxWidth: 300 }}>Checkout</div>
        </Link>
      </div>
    }
  </div>;
}


