import { useFilePicker } from 'use-file-picker';
import EventEmitter from '../../services/event/event-emitter';
import { FileContent } from 'use-file-picker/dist/interfaces';

export class FileChangeEvent {
    key: string;
    file: File[];
    filesContent: FileContent<string>[];
}

export default function StatefullFileSelect({ inputKey, label, classes, fileSelected }: { inputKey: string, label: string, classes?: string, fileSelected?: EventEmitter<FileChangeEvent> }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        onFilesSuccessfullySelected({ plainFiles, filesContent }) {
            if (fileSelected) fileSelected.emit({ key: inputKey, file: plainFiles, filesContent: filesContent });
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errors.length) {
        return <div>Error...</div>;
    }

    return (
        <div className={`${classes}`}>
            <button className='w-100 btn btn-outline-primary mb-1' onClick={() => openFilePicker()}>{label}</button>
        </div>
    );
}