import { useCallback, useEffect, useState } from "react";
import ProductEntity from "../../models/data/product";
import AdminService from "../../services/ui/admin-service";
import Price from "../price/price";
import { useNavigate } from "react-router-dom";

export default function ProductsTabComponent() {

    const [products, setProducts] = useState<ProductEntity[]>([]);
    const navigation = useNavigate();

    useEffect(() => {
        AdminService.GetProducts({
            success: (_: ProductEntity[]) => {
                setProducts(_);
            },
            error: (err: any) => {
                alert('failed to load products');
            }
        })
    }, [])

    const OpenProductHandle = useCallback((product: ProductEntity) => {
        navigation(`/admin/products/edit/${product.handle}`)
    }, [navigation])

    const AddNewProductHandle = useCallback(() => {
        navigation(`/admin/products/create`)
    }, [navigation])

    return <>
        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
            <h3 className="my-0">Products</h3>
            <div>
                <button className="btn btn-dark" onClick={() => AddNewProductHandle()}>Add Product</button>
            </div>
        </div>
        <table className="table rounded-3 overflow-hidden">
            <thead className="bg-secondary text-white">
                <tr>
                    <th>Product</th>
                    <th>HTML Description</th>
                    <th className="text-center"># of Variants</th>
                    <th>Status</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product: ProductEntity, key: number) => {
                    return <tr key={key} className="interact-hover" onClick={() => OpenProductHandle(product)}>
                        <td className="py-2 align-middle">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <div style={{ maxWidth: 70, maxHeight: 70 }} className="border bg-white rounded-3 overflow-hidden">
                                    <img src={product.featuredMedia} alt={product.title} className="d-block w-100" />
                                </div>
                                <p className="my-0">{product.title}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0 w-100">{product.HTMLdescription ? `${product.HTMLdescription.substring(0, 50)}...` : 'N/A'}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-center">
                                <p className="text-center my-0 w-100">{product.variants.length}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0 w-100">{product.available ? 'Active' : 'Draft'}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <Price price={product.price} currencyCode="GBP" />
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </>;
}