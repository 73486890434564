import { FaCertificate, FaHeadset, FaLightbulb, FaLock, FaMedal, FaTruckRampBox } from "react-icons/fa6";

export default function OurAdvantagesPage() {
    return <>
        <div className="container my-5">
            <h1 className="text-center mb-5 pb-5">Our Advantages</h1>
            <div className="">
                <FaLightbulb className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">Knowledge</h2>
                <div className="fs-md-19">
                    <p>We have over 20 years of experience in the fireplace and heating industry, we are able to offer expert designs and functionality.
                        Supported with a professional research and development team, we are fully equipped to develop a full range of fireplaces and space heaters to respond flexibly to market changes and cater to the needs of our customers.</p>

                    <p>Our goal is for every customer to find their ideal fireplace through FLAMME.</p>
                </div>
            </div>

            <div className="mt-5">
                <FaMedal className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">High Quality Assurance</h2>
                <div className="fs-md-19">
                    <p>WAs a fireplace manufacturer with many years of experience, we enforce great importance to the quality of our products.
                        We strictly control every link from raw material procurement to production and to inspection.</p>
                </div>
            </div>

            <div className="mt-5">
                <FaLock className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">Secure Payment System</h2>
                <div className="fs-md-19">
                    <p>Creating a secure payment environment and building trust with our customers is a vital point of any business.
                        Our platform passes the highest level of PCI DSS(Payment Card Industry Data Security)</p>

                    <p>The PCI DSS certificate is valid for only one year, which means that we must go through safety checks every 12 months. As a result, we can guarantee that all of our customers personal information is encrypted and inaccessible by any third party.</p>
                </div>
            </div>

            <div className="mt-5">
                <FaTruckRampBox className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">Fast and Free Delivery</h2>
                <div className="fs-md-19">
                    <p>Cooperating with many logistics companies, we are able to deliver our products fast to your door to all addresses in Mainland United Kingdom, and a slightly delayed service to Channel Islands and Northern Ireland. (Up to 48hr delivery once dispatched)</p>
                </div>
            </div>

            <div className="mt-5">
                <FaCertificate className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">2 Year Warranty</h2>
                <div className="fs-md-19">
                    <p>Returns are allowed within 30 days to ensure your product has arrived in good condition, if the return is not as the sellers fault (ordered wrong colour, dimensions do not fit etc.) there will be a surcharge for the return. All FLAMME appliances come with a standard 2-year warranty. If anything is damaged in transit, we will provide the parts you need to replace free of charge.</p>
                </div>
            </div>

            <div className="mt-5">
                <FaHeadset className="d-block display-1 mx-auto mb-2" />
                <h2 className="text-center">Professional After Sales Service</h2>
                <div className="fs-md-19">
                    <p>We are efficient in dealing with any issues customers come across after purchasing our products.
                        If you have any problems with your product, Call our customer service <a className="text-primary text-decoration-underline" href="tel:01785719005">01785 719005</a> or email us at <a className="text-primary text-decoration-underline" href="mailto:customerservice@moddelo.co.uk">customerservice@moddelo.co.uk</a>. We will promptly respond to your questions and provide professional and technical advice.</p>
                </div>
            </div>
        </div>
    </>;
}